<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('operation_management.operation_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('operation_management.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control"
                       :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('operation_management.default_workstation') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect
                      v-model="workstation"
                      :placeholder="$t('operation_management.default_workstation')"
                      :class="validation && validation.workstation_id ? 'is-invalid' : ''"
                      label="name"
                      track-by="id"
                      :options="workstations"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/manufactories/workstation/create" target="_blank"><i
                        class="fa fa-plus"
                        style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.workstation_id"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.workstation_id[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('operation_management.available_workstation') }}</label>
                <div class="input-group mb-3">
                  <multiselect
                      v-model="data.available_workstation"
                      :placeholder="$t('operation_management.available_workstation')"
                      :class="validation && validation.available_workstation ? 'is-invalid' : ''"
                      label="name"
                      track-by="id"
                      :options="workstations"
                      :multiple="true"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/manufactories/workstation/create" target="_blank"><i
                        class="fa fa-plus"
                        style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.available_workstation"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.available_workstation[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('operation_management.batch_size') }}</label>
                <input type="number" min="0" v-model="data.batch_size" class="form-control"
                       :class="validation && validation.batch_size ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.batch_size" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.batch_size[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('operation_management.operation_time_minutes') }}</label>
                <input type="number" min="0" v-model="data.operation_time_minutes" class="form-control"
                       :class="validation && validation.operation_time_minutes ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.operation_time_minutes"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.operation_time_minutes[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('operation_management.quality_inspection') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect v-model="quality_inspection"
                               :placeholder="$t('operation_management.quality_inspection')"
                               :class="validation && validation.quality_inspection_id ? 'is-invalid' : ''"
                               label="name"
                               track-by="id"
                               :options="quality_inspections"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/manufactories/quality-inspections/create" target="_blank"><i
                        class="fa fa-plus"
                        style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.quality_inspection_id"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.quality_inspection_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mt-10 mb-10">
                <b-form-checkbox size="lg" v-model="data.is_batch_size" name="check-button" switch>
                  {{ $t('operation_management.is_batch_size') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mt-10 mb-10">
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>
                  {{ $t('operation_management.is_active') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mt-10 mb-10">
                <b-form-checkbox size="lg" v-model="data.is_corrective" name="check-button" switch>
                  {{ $t('operation_management.is_corrective') }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('operation_management.sub_operations') }}</h6>
            <button type="button" class="btn btn-primary" @click="addRepeater"><i
                class="fas fa-plus"></i>{{ $t('add_more') }}
            </button>
          </div>
          <div class="bg-white">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">{{ $t('operation_management.operation') }}</th>
                <th scope="col">{{ $t('operation_management.operation_time_minutes') }}</th>
                <th scope="col">{{ $t('operation_management.is_active') }}</th>
                <th scope="col">{{ $t('actions') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in sub_operations" :key="index">
                <td>
                  <multiselect v-model="item.operation"
                               :placeholder="$t('operation_management.operation')"
                               label="name"
                               track-by="id"
                               :options="operations"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="selectItem(index)">
                  </multiselect>
                </td>
                <td>
                  <input type="number" min="0" @input="calcTime" v-model="item.operation_time_minutes"
                         class="form-control" :placeholder="$t('operation_management.operation_time_minutes')"/>
                </td>
                <td>
                  <b-form-checkbox size="lg" v-model="item.is_active" name="check-button" switch>
                    {{ $t('operation_management.is_active') }}
                  </b-form-checkbox>
                </td>
                <td>
                  <button type="button" class="btn btn-link mr-2" @click="removeRepeater(index)"><i
                      class="fas fa-trash text-danger"></i></button>
                </td>
              </tr>


              </tbody>
            </table>
          </div>

        </div>
      </div>


      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('notes')">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('operation_management.description') }}</label>
                <textarea v-model="data.description" class="form-control"
                          :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-5">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-operation-management",
  data() {
    return {
      mainRoute: 'manufacturers/operations',
      mainRouteDependency: 'base/dependency',

      data: {
        name: null,
        workstation_id: null,
        available_workstation: null,
        quality_inspection_id: null,
        description: null,
        batch_size: null,
        operation_time_minutes: null,
        is_batch_size: false,
        is_active: true,
        is_corrective: false,
      },
      sub_operations: [],
      sub_operations_repeater: {
        id: null,
        operation: null,
        operation_id: null,
        operation_time_minutes: null,
        is_active: true
      },
      workstation: null,
      workstations: [],

      quality_inspection: null,
      quality_inspections: [],

      operations: [],

      idEditing: this.$route.params.id,
      isEditing: false,

      validation: null,

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {
    workstation: function (val) {
      if (val)
        this.data.workstation_id = val.id;
      else
        this.data.workstation_id = null;
    },
    quality_inspection: function (val) {
      if (val)
        this.data.quality_inspection_id = val.id;
      else
        this.data.quality_inspection_id = null;
    },

  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        operations: this.sub_operations,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'operation-management.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        operations: this.sub_operations,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'operation-management.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.workstation_id = response.data.data.workstation_id;
        this.data.available_workstation = response.data.data.available_workstation;
        this.data.quality_inspection_id = response.data.data.quality_inspection_id;
        this.data.description = response.data.data.description;
        this.data.batch_size = response.data.data.batch_size;
        this.data.is_batch_size = response.data.data.is_batch_size;
        this.data.is_active = response.data.data.is_active;
        this.data.operation_time_minutes = response.data.data.operation_time_minutes;
        this.data.is_corrective = response.data.data.is_corrective;
        this.workstation = response.data.data.workstation;
        this.quality_inspection = response.data.data.quality_inspection;

        if (response.data.data.operations && response.data.data.operations.length > 0) {
          this.sub_operations = response.data.data.operations;
        } else {
          this.addRepeater();
        }


      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getWorkstations() {
      ApiService.get(this.mainRouteDependency + "/workstations").then((response) => {
        this.workstations = response.data.data;
      });
    },
    getOperations() {
      ApiService.get(this.mainRouteDependency + "/operations").then((response) => {
        this.operations = response.data.data;
      });
    },
    getQualityInspection() {
      ApiService.get(this.mainRouteDependency + "/quality_inspections").then((response) => {
        this.quality_inspections = response.data.data;
      });
    },
    addRepeater() {
      this.sub_operations.unshift(this.sub_operations_repeater);
      this.sub_operations_repeater = {
        id: null,
        operation: null,
        operation_id: null,
        operation_time_minutes: null,
        is_active: true
      };
    },
    removeRepeater(index) {
      if (this.sub_operations.length > 1)
        this.sub_operations.splice(index, 1);
    },
    selectItem(index) {
      this.sub_operations[index].operation_id = this.sub_operations[index].operation ? this.sub_operations[index].operation.id : 0;
    },
    calcTime() {
      this.data.operation_time_minutes = this.$_.sumBy(this.sub_operations, function (row) {
        return row.operation_time_minutes ? parseInt(row.operation_time_minutes) : 0;
      });
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.workstation = response.data.data.workstation;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.operation_management"),
      route: '/manufactories/operation-management'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getWorkstations();
    this.getQualityInspection();
    this.getOperations();

    if (this.idEditing) {
      this.getData();
    } else {
      this.defaultDataForUser();
      this.addRepeater();
    }

  },
};
</script>